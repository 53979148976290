import { Form, Input, Radio, Checkbox, Select, DatePicker, Flex } from "antd";

export default function QuestionOption(props) {
	const {
		data,
		index,
		setStepPercent,
		currentStep,
		dataSource,
		setDataSource,
		answer,
	} = props;

	const handleChange = (value, x) => {
		let new_value = null;

		if (data && data.question_type === "text (Open Answer)") {
			let form_question_answer =
				dataSource.form_question_categories[currentStep].form_questions[
					index
				] &&
				dataSource.form_question_categories[currentStep].form_questions[index]
					.answer
					? dataSource.form_question_categories[currentStep].form_questions[
							index
					  ].answer
					: null;
			form_question_answer = form_question_answer
				? JSON.parse(form_question_answer)
				: [];
			form_question_answer[x] = value;
			new_value = JSON.stringify(form_question_answer);
		} else {
			new_value = value;
		}

		let count =
			dataSource.form_question_categories[currentStep].form_questions.length;
		let percent = 100 / count;
		let dataSourceTemp = dataSource;
		let form_question_categories = dataSourceTemp.form_question_categories;
		let form_questions = form_question_categories[currentStep].form_questions;
		form_questions[index] = {
			...form_questions[index],
			answer: new_value,
		};
		form_question_categories[currentStep] = {
			...form_question_categories[currentStep],
			form_questions,
		};

		dataSourceTemp = {
			...dataSourceTemp,
			form_question_categories,
		};

		let answered = form_questions.filter((item) => item.answer).length;
		setStepPercent(percent * answered);
		setDataSource(dataSourceTemp);
	};

	if (data && data.question_type === "text") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Input
					placeholder="Answer"
					size="large"
					onChange={(e) => handleChange(e.target.value)}
				/>
			</Form.Item>
		);
	} else if (data && data.question_type === "text (Open Answer)") {
		if (data.max_input_field && Number(data.max_input_field) > 0) {
			let inputFields = [];

			for (let x = 0; x < Number(data.max_input_field); x++) {
				inputFields.push(
					<Form.Item
						key={`q_${x}_${data.id}`}
						name={`q_${x}_${data.id}`}
						rules={[
							{
								required: data.required ? true : false,
								message: "Please answer the question!",
							},
						]}
					>
						<Input
							addonBefore={x + 1}
							placeholder={`Answer`}
							size="large"
							onChange={(e) => handleChange(e.target.value, x)}
						/>
					</Form.Item>
				);
			}

			return inputFields;
		} else {
			return (
				<Form.Item
					name={`q_${data.id}`}
					rules={[
						{
							required: data.required ? true : false,
							message: "Please answer the question!",
						},
					]}
				>
					<Input
						placeholder="Answer"
						size="large"
						onChange={(e) => handleChange(e.target.value)}
					/>
				</Form.Item>
			);
		}
	} else if (data && data.question_type === "number") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Input
					placeholder="Answer"
					size="large"
					type="number"
					onChange={(e) => handleChange(e.target.value)}
				/>
			</Form.Item>
		);
	} else if (data && data.question_type === "radio") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Radio.Group
					size="large"
					onChange={(e) => handleChange(e.target.value)}
				>
					<Flex vertical="vertical">
						{data.form_question_options
							? data.form_question_options.map((item, index) => {
									return (
										<Radio key={index} value={item.option}>
											{item.option}
										</Radio>
									);
							  })
							: ""}
					</Flex>
				</Radio.Group>
			</Form.Item>
		);
	} else if (data && data.question_type === "checkbox") {
		let disabled = false;

		if (answer && answer.length === Number(data.max_checkbox)) {
			disabled = true;
		}

		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Checkbox.Group size="large" onChange={(e) => handleChange(e)}>
					<Flex vertical="vertical">
						{data.form_question_options
							? data.form_question_options.map((item, index) => {
									return (
										<Checkbox
											key={index}
											value={item.option}
											disabled={
												disabled
													? answer.find((x) => x === item.option)
														? false
														: true
													: false
											}
										>
											{item.option}
										</Checkbox>
									);
							  })
							: ""}
					</Flex>
				</Checkbox.Group>
			</Form.Item>
		);
	} else if (data && data.question_type === "select") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Select
					placeholder="Select an answer"
					size="large"
					allowClear
					options={
						data.form_question_options
							? data.form_question_options.map((item, index) => ({
									key: index,
									value: item.option,
									label: item.option,
							  }))
							: []
					}
					onChange={(e) => handleChange(e)}
				/>
			</Form.Item>
		);
	} else if (data && data.question_type === "date") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<DatePicker
					size="large"
					format={{
						format: "MM/DD/YYYY",
						type: "mask",
					}}
					onChange={(date, dateString) => {
						handleChange(dateString);
					}}
				/>
			</Form.Item>
		);
	} else {
		return null;
	}
}
