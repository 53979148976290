import { Geolocation } from "@capacitor/geolocation";

export default async function currentLocation() {
	try {
		const coordinates = await Geolocation.getCurrentPosition();
		return coordinates;
	} catch (error) {
		console.error("Geolocation error:", error);
		// Handle the error as needed, e.g., return a default location or error message
		return false;
	}
}
