import { Space, Radio, Checkbox, Flex } from "antd";
import dayjs from "dayjs";

export default function QuestionOptionPreview(props) {
	const { data } = props;

	if (data && data.question_type === "text") {
		return <div className="options">{data.answer ? data.answer : ""}</div>;
	} else if (data && data.question_type === "text (Open Answer)") {
		return (
			<div className="options">
				{data.answer
					? JSON.parse(data.answer).map((item, index) => (
							<Flex vertical="vertical" key={index}>
								<Flex gap={5}>
									<span>{index + 1}.</span>
									<span>{item}</span>
								</Flex>
							</Flex>
					  ))
					: ""}
			</div>
		);
	} else if (data && data.question_type === "number") {
		return <div className="options"> {data.answer ? data.answer : ""}</div>;
	} else if (data && data.question_type === "radio") {
		return (
			<div className="options">
				<Radio.Group size="large" value={data.answer} disabled>
					<Space direction="vertical">
						{data.form_question_options
							? data.form_question_options.map((item, index) => {
									return (
										<Radio key={index} value={item.option}>
											{item.option}
										</Radio>
									);
							  })
							: ""}
					</Space>
				</Radio.Group>
			</div>
		);
	} else if (data && data.question_type === "checkbox") {
		return (
			<div className="options">
				<Checkbox.Group size="large" disabled value={data.answer}>
					<Space direction="vertical">
						{data.form_question_options
							? data.form_question_options.map((item, index) => {
									return (
										<Checkbox key={index} value={item.option}>
											{item.option}
										</Checkbox>
									);
							  })
							: ""}
					</Space>
				</Checkbox.Group>
			</div>
		);
	} else if (data && data.question_type === "select") {
		return <div className="options">{data.answer ? data.answer : ""}</div>;
	} else if (data && data.question_type === "date") {
		return (
			<div className="options">
				{data.answer ? dayjs(data.answer).format("MM/DD/YYYY") : ""}
			</div>
		);
	} else {
		return null;
	}
}
