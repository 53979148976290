import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Card,
	Col,
	Flex,
	Form,
	Row,
	Steps,
	Typography,
	notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";

import { readFile } from "../../../providers/useCapacitorStorage";
import QuestionOption from "./components/QuestionOption";
import ModalPreviewAnswer from "./components/ModalPreviewAnswer";

export default function PageSurveyForm() {
	const params = useParams();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [toggleModalPreview, setToggleModalPreview] = useState(false);
	const [dataSource, setDataSource] = useState(null);
	const [currentStep, setCurrentStep] = useState(0);
	const [stepPercent, setStepPercent] = useState(0);
	const [steps, setSteps] = useState([]);

	useEffect(() => {
		let paramsId = params.id;

		readFile("dsac_survey/survey_pending_list.txt").then((res) => {
			if (res && res.data) {
				res = JSON.parse(res.data);
				let findData = res.find((f) => f.index === parseInt(paramsId));

				if (findData) {
					let questions = findData.form_question_categories[0].form_questions;

					let stepsTemp = [];
					findData.form_question_categories.forEach((item) => {
						stepsTemp.push({
							title: <div className="title">{item.category}</div>,
							content: item,
						});
					});
					setSteps(stepsTemp);

					setDataSource(findData);
					questions.forEach((item) => {
						if (item.question_type === "checkbox") {
							form.setFieldValue(`q_${item.id}`, JSON.parse(item.answer));
						} else if (item.question_type === "text (Open Answer)") {
							let answer = item.answer ? JSON.parse(item.answer) : [];
							for (let x = 0; x < Number(item.max_input_field); x++) {
								form.setFieldValue(`q_${x}_${item.id}`, answer[x]);
							}
						} else if (item.question_type === "date") {
							form.setFieldValue(`q_${item.id}`, dayjs(item.answer));
						} else {
							form.setFieldValue(`q_${item.id}`, item.answer);
						}
					});
				} else {
					notification.warning({
						message: "Warning",
						description: "No data was found.",
						duration: 10,
					});
					navigate("/");
				}
			} else {
				notification.warning({
					message: "Warning",
					description:
						"Unable to read the file: either no permission or the file was not found.",
					duration: 10,
				});
			}
		});
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	const onFinish = (values) => {
		if (currentStep !== dataSource.form_question_categories.length - 1) {
			let currentStepTemp = currentStep + 1;

			let findData = dataSource;

			if (findData) {
				let questions =
					findData.form_question_categories[currentStepTemp].form_questions;

				questions.forEach((item) => {
					if (item.question_type === "checkbox") {
						form.setFieldValue(`q_${item.id}`, JSON.parse(item.answer));
					} else if (item.question_type === "text (Open Answer)") {
						let answer = item.answer ? JSON.parse(item.answer) : [];
						for (let x = 0; x < Number(item.max_input_field); x++) {
							form.setFieldValue(`q_${x}_${item.id}`, answer[x]);
						}
					} else if (item.question_type === "date") {
						form.setFieldValue(`q_${item.id}`, dayjs(item.answer));
					} else {
						form.setFieldValue(`q_${item.id}`, item.answer);
					}
				});
			}

			if (currentStep !== dataSource.form_question_categories.length - 1) {
				let count =
					dataSource.form_question_categories[currentStepTemp].form_questions
						.length;
				let percent = 100 / count;
				let dataSourceTemp = dataSource;
				let form_question_categories = dataSourceTemp.form_question_categories;
				let form_questions =
					form_question_categories[currentStepTemp].form_questions;

				let answered = form_questions.filter((item) => item.answer).length;
				setStepPercent(percent * answered);
			}

			setCurrentStep(currentStepTemp);
		} else {
			setToggleModalPreview(true);
		}
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Row gutter={[12, 0]}>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					xl={24}
					className="form-name-wrapper"
				>
					<Button
						type="link"
						className="btn-back"
						icon={<FontAwesomeIcon icon={faArrowLeft} />}
						onClick={() => navigate(-1)}
					/>
					<div className="form-name">
						<Typography.Title level={5}>
							{dataSource && dataSource.form_name}
						</Typography.Title>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					{steps.length > 0 ? (
						<Card
							title={
								<>
									<div className="title-wrapper">
										{steps[currentStep].title}
									</div>
									<Steps
										current={currentStep}
										percent={stepPercent}
										items={steps}
									/>
								</>
							}
						>
							<div className="steps-mobile-wrapper">
								<Steps
									current={currentStep}
									percent={stepPercent}
									items={steps}
								/>
							</div>

							<div className="question-wrapper">
								{steps[currentStep] &&
								steps[currentStep].content &&
								steps[currentStep].content.form_questions ? (
									<Row gutter={[20, 0]}>
										{steps[currentStep].content.form_questions.map(
											(item, index) => {
												return (
													<Col
														xs={24}
														sm={24}
														md={24}
														lg={24}
														xl={24}
														key={index}
													>
														<div
															className="quill-output question"
															dangerouslySetInnerHTML={{
																__html: item.question,
															}}
														/>

														<Form.Item shouldUpdate noStyle>
															{() => {
																let answer = form.getFieldValue(`q_${item.id}`);

																return (
																	<QuestionOption
																		data={item}
																		index={index}
																		setStepPercent={setStepPercent}
																		currentStep={currentStep}
																		steps={steps}
																		dataSource={dataSource}
																		setDataSource={setDataSource}
																		form={form}
																		answer={answer}
																	/>
																);
															}}
														</Form.Item>
													</Col>
												);
											}
										)}
									</Row>
								) : null}
							</div>
						</Card>
					) : null}
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Flex gap={15} justify="center">
						<Button
							disabled={currentStep === 0}
							onClick={() => {
								if (currentStep > 0) {
									let currentStepTemp = currentStep - 1;

									let findData = dataSource;

									if (findData) {
										let questions =
											findData.form_question_categories[currentStepTemp]
												.form_questions;
										questions.forEach((item) => {
											if (item.question_type === "checkbox") {
												form.setFieldValue(`q_${item.id}`, item.answer);
											} else if (item.question_type === "text (Open Answer)") {
												let answer = item.answer ? JSON.parse(item.answer) : [];
												for (let x = 0; x < Number(item.max_input_field); x++) {
													form.setFieldValue(`q_${x}_${item.id}`, answer[x]);
												}
											} else if (item.question_type === "date") {
												form.setFieldValue(`q_${item.id}`, dayjs(item.answer));
											} else {
												form.setFieldValue(`q_${item.id}`, item.answer);
											}
										});
									}

									let count =
										dataSource.form_question_categories[currentStepTemp]
											.form_questions.length;
									let percent = 100 / count;
									let dataSourceTemp = dataSource;
									let form_question_categories =
										dataSourceTemp.form_question_categories;
									let form_questions =
										form_question_categories[currentStepTemp].form_questions;

									let answered = form_questions.filter(
										(item) => item.answer
									).length;
									setStepPercent(percent * answered);
									setCurrentStep(currentStepTemp);
								} else {
									setCurrentStep(0);
								}
							}}
						>
							Back
						</Button>

						{currentStep ===
						(dataSource && dataSource.form_question_categories.length - 1) ? (
							<Button
								htmlType="submit"
								type="primary"
								className="btn-main-primary"
							>
								Preview
							</Button>
						) : (
							<Button
								htmlType="submit"
								type="primary"
								className="btn-main-primary"
							>
								Next
							</Button>
						)}
					</Flex>
				</Col>
			</Row>

			<ModalPreviewAnswer
				toggleModalPreview={toggleModalPreview}
				setToggleModalPreview={setToggleModalPreview}
				dataSource={dataSource}
			/>
		</Form>
	);
}
