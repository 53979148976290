import { useState } from "react";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Card, Form, Layout, Typography } from "antd";

import {
	date,
	description,
	encrypt,
	logo,
	name,
} from "../../../providers/companyInfo";
import { checkNetworkStatus } from "../../../providers/useCapacitorStorage";
import { POST } from "../../../providers/useAxiosQuery";
import FloatInput from "../../../providers/FloatInput";
import FloatInputPassword from "../../../providers/FloatInputPassword";

export default function PageLogin() {
	const [errorMessageLogin, setErrorMessageLogin] = useState({
		type: "",
		message: "",
		action: "",
	});

	const { mutate: mutateLogin, isLoading: isLoadingButtonLogin } = POST(
		"api/login",
		"login"
	);

	const onFinishLogin = (values) => {
		checkNetworkStatus().then((res) => {
			if (res.connected) {
				let data = { ...values, from: "faculty_monitoring_attendance_checker" };

				mutateLogin(data, {
					onSuccess: (res) => {
						if (res.data) {
							localStorage.userdata = encrypt(JSON.stringify(res.data));
							localStorage.token = res.token;
							window.location.reload();
						} else {
							setErrorMessageLogin({
								type: "error",
								message: res.message,
								action: "",
							});
						}
					},
					onError: (err) => {
						setErrorMessageLogin({
							type: "error",
							message: (
								<>
									Unrecognized username or password.{" "}
									<b>Forgot your password?</b>
								</>
							),
							action: "",
						});
					},
				});
			} else {
				setErrorMessageLogin({
					type: "error",
					message: <>Please connect to the internet</>,
					action: (
						<Button
							type="link"
							className="btn-edit p-0"
							icon={<FontAwesomeIcon icon={faArrowsRotate} />}
							onClick={() => window.location.reload()}
						/>
					),
				});
			}
		});
	};

	return (
		<Layout.Content>
			<div />

			<Card>
				<div className="logo-wrapper zoom-in-out-box-1 p-0">
					<img src={logo} alt={name} />
				</div>

				<Typography.Title
					level={3}
					className="text-center text-sign-in-here mb-20"
				>
					Sign in
				</Typography.Title>

				<Form
					layout="vertical"
					className="login-form"
					onFinish={onFinishLogin}
					autoComplete="off"
				>
					<Form.Item
						name="email"
						rules={[
							{
								required: true,
								message: "This field is required",
							},
						]}
						hasFeedback
					>
						<FloatInput
							label="Username / E-mail"
							placeholder="Username / E-mail"
						/>
					</Form.Item>

					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: "This field is required",
							},
						]}
						hasFeedback
					>
						<FloatInputPassword label="Password" placeholder="Password" />
					</Form.Item>

					<Button
						type="primary"
						htmlType="submit"
						loading={isLoadingButtonLogin}
						className="btn-main mt-10 btn-sign-in"
						block
						size="large"
					>
						SIGN IN
					</Button>

					{errorMessageLogin.message && (
						<Alert
							className="mt-10"
							type={errorMessageLogin.type}
							message={errorMessageLogin.message}
							action={errorMessageLogin.action}
						/>
					)}
				</Form>
			</Card>

			<Layout.Footer>
				<Typography.Text>
					{`© ${date.getFullYear()} ${description}. All Rights
                        Reserved.`}
				</Typography.Text>
			</Layout.Footer>
		</Layout.Content>
	);
}
