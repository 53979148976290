import { Routes, Route } from "react-router-dom";
import {
	faFileCircleQuestion,
	faFilePen,
} from "@fortawesome/pro-regular-svg-icons";
import { faUserAlt } from "@fortawesome/pro-solid-svg-icons";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Page404 from "../views/errors/Page404";

import PageLogin from "../views/public/PageLogin/PageLogin";
import PageDashboard from "../views/private/PageDashboard/PageDashboard";
import PageSurveyForm from "../views/private/PageSurvey/PageSurveyForm";
import PageSurveyPending from "../views/private/PageSurveyPending/PageSurveyPending";
import PageNews from "../views/private/PageNews/PageNews";
import PageProfile from "../views/private/PageProfile/PageProfile";

export default function RouteList() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>

			<Route
				path="/dashboard"
				element={
					<PrivateRoute
						title="Dashboard"
						subtitle="SURVEY"
						pageId="PageDashboard"
						pageHeaderIcon={faFileCircleQuestion}
						breadcrumb={[]}
						component={PageDashboard}
					/>
				}
			/>

			<Route
				path="/survey/:id"
				element={
					<PrivateRoute
						title="Survey"
						subtitle="FORM"
						pageId="PageSurveyForm"
						pageHeaderIcon={faFilePen}
						breadcrumb={[
							{
								name: "Survey",
							},
							{
								name: "Form",
							},
						]}
						component={PageSurveyForm}
					/>
				}
			/>

			<Route
				path="/survey/pending"
				element={
					<PrivateRoute
						title="Survey"
						subtitle="PENDING"
						pageId="PageSurveyPending"
						pageHeaderIcon={faFilePen}
						breadcrumb={[
							{
								name: "Survey",
							},
							{
								name: "Pending",
							},
						]}
						component={PageSurveyPending}
					/>
				}
			/>

			<Route
				path="/news"
				element={
					<PrivateRoute
						title="News"
						subtitle="VIEW"
						pageId="PageNews"
						pageHeaderIcon={faFilePen}
						breadcrumb={[
							{
								name: "News",
							},
						]}
						component={PageNews}
					/>
				}
			/>

			<Route
				path="/account-profile"
				element={
					<PrivateRoute
						title="Profile"
						subtitle="ACCOUNT"
						pageId="PageAccountProfile"
						pageHeaderIcon={faUserAlt}
						breadcrumb={[
							{
								name: "Profile",
							},
						]}
						component={PageProfile}
					/>
				}
			/>

			<Route path="*" element={<Page404 pageId="Page404" />} />
		</Routes>
	);
}
