import { useEffect, useState } from "react";
import { Card, Col, Image, Row, Button, Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/pro-solid-svg-icons";

import { apiUrl, userData } from "../../../providers/companyInfo";

export default function PageProfile() {
	const [profilePicture, setProfilePicture] = useState(null);

	useEffect(() => {
		console.log("userData()", userData());
		if (userData().profile_picture) {
			let profile_picture = userData().profile_picture.split("//");

			if (profile_picture[0] === "http:" || profile_picture[0] === "https:") {
				setProfilePicture(userData().profile_picture);
			} else {
				setProfilePicture(apiUrl(userData().profile_picture));
			}
		}

		return () => {};
	}, []);

	// const handleLogout = () => {
	// 	deleteFile("dsac_survey/form_list.txt").then((res) => {
	// 		deleteFile("dsac_survey/survey_pending_list.txt").then((res) => {
	// 			localStorage.clear();
	// 			window.location.replace("/");
	// 		});
	// 	});
	// };

	return (
		<Card className="card-account-profile-info">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<div>Name: {`${userData().firstname} ${userData().lastname}`}</div>
					<div>
						Role: <strong>{`${userData().role}`}</strong>
					</div>
					<div className="mt-5">
						<Popconfirm
							placement="topLeft"
							description="Are you sure to logout?"
							okText="Yes"
							cancelText="No"
						>
							<Button
								icon={
									<FontAwesomeIcon icon={faRightFromBracket} className="mr-5" />
								}
							>
								Logout
							</Button>
						</Popconfirm>
					</div>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} className="text-center">
					<Image src={profilePicture} />
				</Col>
			</Row>
		</Card>
	);
}
