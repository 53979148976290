import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import RouteList from "./RouteList";

import "../assets/scss/app.scss";
import currentLocation from "../providers/currentLocation";

const queryClient = new QueryClient();

export default function Routers() {
	useEffect(() => {
		const fetchLocation = async () => {
			try {
				let localStorageCurrentLocations = localStorage.getItem(
					"currentLocations"
				)
					? JSON.parse(localStorage.getItem("currentLocations"))
					: null;

				// Check if the location is already stored and has the necessary properties
				if (
					!localStorageCurrentLocations ||
					!localStorageCurrentLocations.coords
				) {
					const currentLocations = await currentLocation(); // Ensure this function is defined and returns a promise

					// Check if the fetched location has the necessary coordinates
					if (
						currentLocations &&
						currentLocations.coords &&
						currentLocations.coords.latitude &&
						currentLocations.coords.longitude
					) {
						localStorage.setItem(
							"currentLocations",
							JSON.stringify(currentLocations)
						);
					}
				}
			} catch (error) {
				console.error("Failed to fetch or store location:", error);
			}
		};

		fetchLocation();
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<RouteList />
			</Router>
		</QueryClientProvider>
	);
}
